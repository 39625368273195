html,
body {
  background: #312f3c;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 20px;
}

.spacebar-app {
  text-align: center;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: height 0.3s ease;
  overflow: hidden;
  background-image: url(assets/spacebar.webp);
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 70%;
  z-index: 100;
}

.header h1 {
  opacity: 0;
  color: black;
}

@keyframes glow {
  0% {
    opacity: 0;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ff00ff,
                 0 0 30px #ff00ff,
                 0 0 40px #ff00ff,
                 0 0 50px #ff00ff,
                 0 0 60px #ff00ff;
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ff00ff,
                 0 0 30px #ff00ff,
                 0 0 40px #ff00ff,
                 0 0 50px #ff00ff,
                 0 0 100px #ff00ff;
  }
}

.header-small {
  height: 100px;
}

.header.header-small h1 {
  animation: glow 1s ease-in-out forwards;
}

@keyframes glow-purple {
  0% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ff00ff,
                 0 0 30px #ff00ff,
                 0 0 40px #ff00ff,
                 0 0 50px #ff00ff,
                 0 0 60px #ff00ff;
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ff00ff,
                 0 0 30px #ff00ff,
                 0 0 40px #ff00ff,
                 0 0 50px #ff00ff,
                 0 0 100px #ff00ff;
  }
  100% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ff00ff,
                 0 0 30px #ff00ff,
                 0 0 40px #ff00ff,
                 0 0 50px #ff00ff,
                 0 0 60px #ff00ff;
  }
}

@keyframes glow-blue {
  0% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #003cff,
                 0 0 30px #003cff,
                 0 0 40px #003cff,
                 0 0 50px #003cff,
                 0 0 60px #003cff;
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #003cff,
                 0 0 30px #003cff,
                 0 0 40px #003cff,
                 0 0 50px #003cff,
                 0 0 100px #003cff;
  }
  100% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #003cff,
                 0 0 30px #003cff,
                 0 0 40px #003cff,
                 0 0 50px #003cff,
                 0 0 60px #003cff;
  }
}

@keyframes glow-yellow {
  0% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ffe600,
                 0 0 30px #ffe600,
                 0 0 40px #ffe600,
                 0 0 50px #ffe600,
                 0 0 60px #ffe600;
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ffe600,
                 0 0 30px #ffe600,
                 0 0 40px #ffe600,
                 0 0 50px #ffe600,
                 0 0 100px #ffe600;
  }
  100% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ffe600,
                 0 0 30px #ffe600,
                 0 0 40px #ffe600,
                 0 0 50px #ffe600,
                 0 0 60px #ffe600;
  }
}

@keyframes glow-green {
  0% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #44ff00,
                 0 0 30px #44ff00,
                 0 0 40px #44ff00,
                 0 0 50px #44ff00,
                 0 0 60px #44ff00;
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #44ff00,
                 0 0 30px #44ff00,
                 0 0 40px #44ff00,
                 0 0 50px #44ff00,
                 0 0 100px #44ff00;
  }
  100% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #44ff00,
                 0 0 30px #44ff00,
                 0 0 40px #44ff00,
                 0 0 50px #44ff00,
                 0 0 60px #44ff00;
  }
}

@keyframes glow-red {
  0% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ff0000,
                 0 0 30px #ff0000,
                 0 0 40px #ff0000,
                 0 0 50px #ff0000,
                 0 0 60px #ff0000;
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ff0000,
                 0 0 30px #ff0000,
                 0 0 40px #ff0000,
                 0 0 50px #ff0000,
                 0 0 100px #ff0000;
  }
  100% {
    opacity: 0.8;
    text-shadow: 0 0 5px #fff,
                 0 0 10px #fff,
                 0 0 20px #ff0000,
                 0 0 30px #ff0000,
                 0 0 40px #ff0000,
                 0 0 50px #ff0000,
                 0 0 60px #ff0000;
  }
}